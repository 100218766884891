/** libraries */
import { types, Instance } from 'mobx-state-tree';
import { toJS } from 'mobx';
/** interfaces */
import { MyTVFieldsModel } from './models/TelevisionModel';
import { Channel, ChannelPackage, MyTVFields } from '../interfaces';
/** constants */
import {
  CardStates,
  CHANNEL_DESCRIPTION_ID,
  PACKAGE_DESCRIPTION_ID,
  STORE_TYPE,
} from '../constants';
/** utils */
import { scrollToBlockById } from '~/components/Blocks/Shared/Shared.utils';

const MyTVModelStore = types
  .model({
    fields: types.maybeNull(MyTVFieldsModel),
    storeType: types.maybeNull(types.enumeration(Object.values(STORE_TYPE))),
    /** Активная карточка канала */
    channel: types.maybeNull(types.string),
    /** Активная карточка пакета */
    package: types.maybeNull(types.string),
    /** id карточки открытого канала/пакета для scroll */
    openCardBlockId: types.maybeNull(types.string),
    /** Состояние блока */
    cardState: types.enumeration(Object.values(CardStates)),
    /** Режим поиска */
    searchMode: types.boolean,
    /** Режим редактирования трансформера */
    isEditTransformer: types.boolean,
    isDesktop940: types.boolean,
  })
  .views((self) => ({
    get summaryData() {
      return toJS(self.fields.summary);
    },
    get channelsByGroups() {
      return toJS(self.fields.channelsByGroups);
    },
    get seriesCode() {
      return self.fields.seriesCode;
    },
    get baseSlug() {
      return self.fields.baseSlug;
    },
    /** Лимит трансформации */
    get transformationLimit() {
      return self.fields.transformationLimit;
    },
    get transformerSlug() {
      return self.fields.transformerSlug;
    },
    /** Убрать акционные пакеты для блока Телевидение */
    get filteredPackages() {
      const filteredPackages =
        self.fields.packages.filter((item) => !item.isTrial) ?? [];
      return toJS(filteredPackages);
    },
    get activeChannelCard() {
      return toJS(JSON.parse(self.channel));
    },
    get activePackageCard() {
      return toJS(JSON.parse(self.package));
    },
    /** Каналы по группам с дополненными полями из packages */
    get augmentedChannelsByGroups() {
      const filteredPackages =
        self.fields.packages.filter((item) => !item.isTrial) ?? [];

      /** Полный список каналов из packages */
      const channelsListFromPackages = filteredPackages.reduce((acc, item) => {
        const channelsArr = item.channelPackChannels.filter((pack) => {
          return !acc.find((canal) => canal.id === pack.id);
        });
        acc.push(...channelsArr);
        return acc;
      }, []);

      const augmentedChannelsByGroups = self.fields.channelsByGroups.map(
        (item) => {
          const canals = item.channels
            .map((itemCanal) => {
              const canal = channelsListFromPackages.find(
                (channel) => channel.id === itemCanal.weburgId,
              );
              // Отсеиваем некорректно добавленные каналы
              if (!canal?.id) return null;
              /** Насильная передача параметров, чтобы значения не затёрлось на undefined */
              return {
                ...canal,
                ...itemCanal,
                id: canal.id,
                idRis: canal.idRis,
                weburgId: canal.weburgId,
              };
            })
            .filter(Boolean);
          return {
            ...item,
            channels: canals,
          };
        },
      );
      return toJS(augmentedChannelsByGroups);
    },
  }))
  .views((self) => ({
    get channelsList() {
      const channelsList = self.augmentedChannelsByGroups.flatMap(
        (item) => item.channels,
      );
      return toJS(channelsList);
    },
  }))
  .views((self) => ({
    /** Популярные каналы */
    get popularChannels() {
      if (self.channelsList) {
        const list = self.channelsList
          .filter((item) => item.single_channel_packs)
          .sort(
            (a, b) =>
              b.single_channel_packs[0].price - a.single_channel_packs[0].price,
          );
        list.length = 1;
        return toJS(list);
      }
      return [];
    },
    /** Популярные пакеты */
    get popularPackages() {
      if (self.filteredPackages?.length) {
        const packs = self.filteredPackages
          .filter((item) => !item?.isOrdered)
          .sort((a, b) => b.price - a.price);
        packs.length = 3;
        return toJS(packs);
      }
      return [];
    },
  }))
  .actions((self) => ({
    setActiveChannelCard: (channel: Channel) => {
      self.channel = JSON.stringify(channel);
    },
    setActivePackageCard: (pack: ChannelPackage) => {
      self.package = JSON.stringify(pack);
    },
    setOpenCardBlockId: (blockId: string) => {
      self.openCardBlockId = blockId;
    },
    setCardState: (card: CardStates) => {
      self.cardState = card;
    },
    setSearchMode: (mode: boolean) => {
      self.searchMode = mode;
    },
    setIsDesktop940: (isDesktop940: boolean) => {
      self.isDesktop940 = isDesktop940;
    },
    setIsEditTransformer: (isEdit: boolean) => {
      self.isEditTransformer = isEdit;
    },
    setStoreType: (type: STORE_TYPE) => {
      self.storeType = type;
    },
  }))
  .actions((self) => ({
    /** Обработчик клика на карточку канала */
    onClickChannelCard: (channel: Channel) => {
      self.setActiveChannelCard(channel);
      self.setCardState(CardStates.CHANNEL_DESCRIPTION);
      self.setSearchMode(false);
      self.setOpenCardBlockId(channel.name);
      if (!self.isDesktop940)
        setTimeout(() => {
          scrollToBlockById(CHANNEL_DESCRIPTION_ID, 'auto');
        }, 0);
    },
    /** Обработчик клика на карточку пакета */
    onClickPackageCard: (packet: ChannelPackage) => {
      self.setActivePackageCard(packet);
      self.setCardState(CardStates.PACKAGE_DESCRIPTION);
      self.setSearchMode(false);
      self.setOpenCardBlockId(packet.channelPackName ?? packet.name);
      if (!self.isDesktop940)
        setTimeout(() => {
          scrollToBlockById(PACKAGE_DESCRIPTION_ID, 'auto');
        }, 0);
    },
  }));

const createMyTVStore = (
  fields: MyTVFields,
  storeType: STORE_TYPE,
): IMyTVModelStore => {
  return MyTVModelStore.create({
    fields,
    storeType,
    channel: null,
    package: null,
    openCardBlockId: null,
    cardState: CardStates.MAIN,
    searchMode: false,
    isEditTransformer: false,
    isDesktop940: false,
  });
};

export type IMyTVModelStore = Instance<typeof MyTVModelStore>;
export default createMyTVStore;
